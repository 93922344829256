import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import styled from "@emotion/styled";
import EditIcon from "../../assets/Edit";
import DeleteIcon from "../../assets/Delete";
import { ReactNode } from "react";



const MenuStyled = styled(Menu)`
  border: 1px solid #e2e6e8;
`;
export const SelectMenu = styled(MenuItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  border: 0;
  background: #fff;
  margin: 8px 4px;
  color: #172882;
  & > :first-of-type {
    box-sizing: border-box;
    height: 16px;
  }
`;

export interface HoverMenuProps {
  id: number;
  onItemClick: (id: number, mode: string) => void;
  children?: ReactNode;
}

export default function DropDownClientReport({ id, onItemClick, children }: HoverMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const OnMenuItemClicked = (mode: string) => {
    handleClose();
    onItemClick(id, mode);
  };

  return (
    <div>
      <IconButton
        aria-label='more'
        // id='long-button'
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon style={{ fill: "#172882" }} />
      </IconButton>
      <MenuStyled
        id='long-menu'
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <SelectMenu onClick={() => OnMenuItemClicked("craving-report")} disableRipple>
          {/* <EditIcon /> */}
          <div>Craving History Report</div>
        </SelectMenu>

        <SelectMenu onClick={() => OnMenuItemClicked("note-report")} disableRipple>
          {/* <EditIcon /> */}
          <div>Notes Usage Report</div>
        </SelectMenu>

        <SelectMenu onClick={() => OnMenuItemClicked("reminder-report")} disableRipple>
          {/* <EditIcon /> */}
          <div>Reminder Usage Report</div>
        </SelectMenu>

        { children }
        {/* <SelectMenu disableRipple>
          <DeactivateIcon />
          Deactivate
        </SelectMenu> */}
      </MenuStyled>
    </div>
  );
}
