interface paramInterface {
  id?: number | string | string[] | undefined;
  pageNum?: number;
  searchTerm?: string;
  RecordPerPage?: number;

  token?: string;
}

export interface RitualAnswersParamI extends paramInterface {
  userId?: number;
  notesId?: number
}

export const HOST_URL = process.env.REACT_APP_API_URL

const ROOT_ROUTE = "/api";

export const BASE_API_ROUTE = HOST_URL + ROOT_ROUTE;

export const routesName = {
  AuthRoute({ token }: paramInterface) {
    return {
      login: `${HOST_URL + ROOT_ROUTE}/auth/login`,
      register: `${HOST_URL + ROOT_ROUTE}/auth/create`,
      refreshToken: `${HOST_URL + ROOT_ROUTE}/auth/refresh/token`,
      forgotPassword: `${HOST_URL + ROOT_ROUTE}/auth/forgotpassword`,
      changePassword: `${HOST_URL + ROOT_ROUTE}/auth/changepassword`,
      resetPassword: `${HOST_URL + ROOT_ROUTE}/auth/resetpassword/${token}`,
    };
  },
  UserRoute({ id, pageNum, searchTerm, RecordPerPage }: paramInterface) {
    return {
      get_users:
        pageNum || RecordPerPage
          ? `${HOST_URL + ROOT_ROUTE}/users/?page=${pageNum ?? 0}&limit=${
              RecordPerPage ?? 10
            }`
          : `${HOST_URL + ROOT_ROUTE}/users`,
      add_user: `${HOST_URL + ROOT_ROUTE}/user`,
      get_coaches: `${HOST_URL + ROOT_ROUTE}/coach`,
      update_user: `${HOST_URL + ROOT_ROUTE}/user/${id}`,
      delete_user: `${HOST_URL + ROOT_ROUTE}/user/${id}`,
      get_single_user: `${HOST_URL + ROOT_ROUTE}/user/${id}`,
      search_user: `${
        HOST_URL + ROOT_ROUTE
      }/users/search?searchTerm=${encodeURI(searchTerm!)}&page=${
        pageNum ?? 0
      }&limit=${RecordPerPage ?? 10}`,
      get_clients_of_coach: `${HOST_URL + ROOT_ROUTE}/user/${id}/clients`,
      getCsvUser: `${HOST_URL + ROOT_ROUTE}/csv/users`,
    };
  },

  ClassesRoute({ id, pageNum, searchTerm, RecordPerPage }: paramInterface) {
    return {
      get_classes:
        pageNum || RecordPerPage
          ? `${HOST_URL + ROOT_ROUTE}/classes/?page=${pageNum ?? 0}&limit=${
              RecordPerPage ?? 10
            }`
          : `${HOST_URL + ROOT_ROUTE}/classes`,
      add_classes: `${HOST_URL + ROOT_ROUTE}/classes`,
      update_classes: `${HOST_URL + ROOT_ROUTE}/classes/${id}`,
      delete_classes: `${HOST_URL + ROOT_ROUTE}/classes/${id}`,
      get_single_classes: `${HOST_URL + ROOT_ROUTE}/classes/${id}`,
      search_classes: `${
        HOST_URL + ROOT_ROUTE
      }/classes/search?searchTerm=${encodeURI(searchTerm!)}&page=${
        pageNum ?? 0
      }&limit=${RecordPerPage ?? 10}`,
    };
  },

  UserRole({ id, pageNum, searchTerm, RecordPerPage }: paramInterface) {
    return {
      get_userrole:
        pageNum || RecordPerPage
          ? `${HOST_URL + ROOT_ROUTE}/userrole/?page=${pageNum ?? 0}&limit=${
              RecordPerPage ?? 10
            }`
          : `${HOST_URL + ROOT_ROUTE}/userrole`,
      add_userrole: `${HOST_URL + ROOT_ROUTE}/userrole`,
      update_userrole: `${HOST_URL + ROOT_ROUTE}/userrole/${id}`,
      delete_userrole: `${HOST_URL + ROOT_ROUTE}/userrole/${id}`,
      get_single_userrole: `${HOST_URL + ROOT_ROUTE}/userrole/${id}`,
      search_userrole: `${
        HOST_URL + ROOT_ROUTE
      }/userrole/search?searchTerm=${encodeURI(searchTerm!)}&page=${
        pageNum ?? 0
      }&limit=${RecordPerPage ?? 10}`,
    };
  },

  BehaviorCategoryRoute({ id, pageNum, searchTerm, RecordPerPage }: paramInterface) {
    return {
      get_behavior_category:
        pageNum || RecordPerPage
          ? `${HOST_URL + ROOT_ROUTE}/behaviorcategory/?page=${pageNum ?? 0}&limit=${
              RecordPerPage ?? 10
            }`
          : `${HOST_URL + ROOT_ROUTE}/behaviorcategory`,
      add_behavior_category: `${HOST_URL + ROOT_ROUTE}/behaviorcategory`,
      update_behavior_category: `${HOST_URL + ROOT_ROUTE}/behaviorcategory/${id}`,
      delete_behavior_category: `${HOST_URL + ROOT_ROUTE}/behaviorcategory/${id}`,
      get_single_behavior_category: `${HOST_URL + ROOT_ROUTE}/behaviorcategory/${id}`,
      search_behavior_category: `${
        HOST_URL + ROOT_ROUTE
      }/behaviorcategory/search?searchTerm=${encodeURI(searchTerm!)}&page=${
        pageNum ?? 0
      }&limit=${RecordPerPage ?? 10}`,
    };
  },

  BehaviorRoute({ id, pageNum, searchTerm, RecordPerPage }: paramInterface) {
    return {
      get_behavior:
        pageNum || RecordPerPage
          ? `${HOST_URL + ROOT_ROUTE}/behavior/?page=${pageNum ?? 0}&limit=${
              RecordPerPage ?? 10
            }`
          : `${HOST_URL + ROOT_ROUTE}/behavior`,
      add_behavior: `${HOST_URL + ROOT_ROUTE}/behavior`,
      update_behavior: `${HOST_URL + ROOT_ROUTE}/behavior/${id}`,
      delete_behavior: `${HOST_URL + ROOT_ROUTE}/behavior/${id}`,
      get_single_behavior: `${HOST_URL + ROOT_ROUTE}/behavior/${id}`,
      search_behavior: `${
        HOST_URL + ROOT_ROUTE
      }/behavior/search?searchTerm=${encodeURI(searchTerm!)}&page=${
        pageNum ?? 0
      }&limit=${RecordPerPage ?? 10}`,
    };
  },

  CallsToActionRoute({ id, pageNum, searchTerm, RecordPerPage }: paramInterface) {
    return {
      get_callstoaction:
        pageNum || RecordPerPage
          ? `${HOST_URL + ROOT_ROUTE}/callstoaction/?page=${pageNum ?? 0}&limit=${
              RecordPerPage ?? 10
            }`
          : `${HOST_URL + ROOT_ROUTE}/callstoaction`,
      add_callstoaction: `${HOST_URL + ROOT_ROUTE}/callstoaction`,
      update_callstoaction: `${HOST_URL + ROOT_ROUTE}/callstoaction/${id}`,
      delete_callstoaction: `${HOST_URL + ROOT_ROUTE}/callstoaction/${id}`,
      get_single_callstoaction: `${HOST_URL + ROOT_ROUTE}/callstoaction/${id}`,
      search_callstoaction: `${
        HOST_URL + ROOT_ROUTE
      }/callstoaction/search?searchTerm=${encodeURI(searchTerm!)}&page=${
        pageNum ?? 0
      }&limit=${RecordPerPage ?? 10}`,
    };
  },

  MeasurementRoute({ id, pageNum, searchTerm, RecordPerPage }: paramInterface) {
    return {
      get_measurement:
        pageNum || RecordPerPage
          ? `${HOST_URL + ROOT_ROUTE}/measurement/?page=${pageNum ?? 0}&limit=${
              RecordPerPage ?? 10
            }`
          : `${HOST_URL + ROOT_ROUTE}/measurement`,
      add_measurement: `${HOST_URL + ROOT_ROUTE}/measurement`,
      update_measurement: `${HOST_URL + ROOT_ROUTE}/measurement/${id}`,
      delete_measurement: `${HOST_URL + ROOT_ROUTE}/measurement/${id}`,
      get_single_measurement: `${HOST_URL + ROOT_ROUTE}/measurement/${id}`,
      search_measurement: `${
        HOST_URL + ROOT_ROUTE
      }/measurement/search?searchTerm=${encodeURI(searchTerm!)}&page=${
        pageNum ?? 0
      }&limit=${RecordPerPage ?? 10}`,
    };
  },

  NoteRoute({ id, pageNum, searchTerm, RecordPerPage }: paramInterface) {
    return {
      get_notes:
        pageNum || RecordPerPage
          ? `${HOST_URL + ROOT_ROUTE}/notes/?page=${pageNum ?? 0}&limit=${
              RecordPerPage ?? 10
            }`
          : `${HOST_URL + ROOT_ROUTE}/notes`,
      add_note: `${HOST_URL + ROOT_ROUTE}/note`,
      update_note: `${HOST_URL + ROOT_ROUTE}/note/${id}`,
      delete_note: `${HOST_URL + ROOT_ROUTE}/note/${id}`,
      get_single_note: `${HOST_URL + ROOT_ROUTE}/note/${id}`,
      get_notes_by_clientId: `${HOST_URL + ROOT_ROUTE}/notes/${id}`,
      notes_reorder: `${HOST_URL + ROOT_ROUTE}/notes/reorder`,
      search_note: `${
        HOST_URL + ROOT_ROUTE
      }/note/search?searchTerm=${encodeURI(searchTerm!)}&page=${
        pageNum ?? 0
      }&limit=${RecordPerPage ?? 10}`,
    };
  },

  SequenceRoute({ id, pageNum, searchTerm, RecordPerPage }: paramInterface) {
    return {
      get_sequences:
        pageNum || RecordPerPage
          ? `${HOST_URL + ROOT_ROUTE}/sequences/?page=${pageNum ?? 0}&limit=${
              RecordPerPage ?? 10
            }`
          : `${HOST_URL + ROOT_ROUTE}/sequences`,
      add_sequence: `${HOST_URL + ROOT_ROUTE}/sequence`,
      update_sequence: `${HOST_URL + ROOT_ROUTE}/sequence/${id}`,
      delete_sequence: `${HOST_URL + ROOT_ROUTE}/sequence/${id}`,
      get_single_sequence: `${HOST_URL + ROOT_ROUTE}/sequence/${id}`,
      get_sequences_by_clientId: `${HOST_URL + ROOT_ROUTE}/sequences/${id}`,
      sequences_reorder: `${HOST_URL + ROOT_ROUTE}/sequences/reorder`,
      duplicate_sequence: `${HOST_URL + ROOT_ROUTE}/sequences/duplicate`,

      search_sequence: `${
        HOST_URL + ROOT_ROUTE
      }/sequence/search?searchTerm=${encodeURI(searchTerm!)}&page=${
        pageNum ?? 0
      }&limit=${RecordPerPage ?? 10}`,
    };
  },

  ReminderRoute({ id, pageNum, searchTerm, RecordPerPage }: paramInterface) {
    return {
      get_reminders:
        pageNum || RecordPerPage
          ? `${HOST_URL + ROOT_ROUTE}/reminders/?page=${pageNum ?? 0}&limit=${
              RecordPerPage ?? 10
            }`
          : `${HOST_URL + ROOT_ROUTE}/reminders`,
      add_reminder: `${HOST_URL + ROOT_ROUTE}/reminder`,
      update_reminder: `${HOST_URL + ROOT_ROUTE}/reminder/${id}`,
      delete_reminder: `${HOST_URL + ROOT_ROUTE}/reminder/${id}`,
      get_single_reminder: `${HOST_URL + ROOT_ROUTE}/reminder/${id}`,
      get_reminders_by_clientId: `${HOST_URL + ROOT_ROUTE}/reminders/${id}`,
      reminders_reorder : `${HOST_URL + ROOT_ROUTE}/reminders/reorder`,
      search_reminder: `${
        HOST_URL + ROOT_ROUTE
      }/reminder/search?searchTerm=${encodeURI(searchTerm!)}&page=${
        pageNum ?? 0
      }&limit=${RecordPerPage ?? 10}`,
    };
  },

  SequenceNoteRoute({ id, pageNum, searchTerm, RecordPerPage }: paramInterface) {
    return {
      get_sequencenotes:
        pageNum || RecordPerPage
          ? `${HOST_URL + ROOT_ROUTE}/sequencenotes/?page=${pageNum ?? 0}&limit=${
              RecordPerPage ?? 10
            }`
          : `${HOST_URL + ROOT_ROUTE}/sequencenotes`,
      add_sequencenotes: `${HOST_URL + ROOT_ROUTE}/sequencenote`,
      update_sequencenotes: `${HOST_URL + ROOT_ROUTE}/sequencenote/${id}`,
      delete_sequencenotes: `${HOST_URL + ROOT_ROUTE}/sequencenote/${id}`,
      get_single_sequencenotes: `${HOST_URL + ROOT_ROUTE}/sequencenote/${id}`,
      get_sequencenotes_by_clientId: `${HOST_URL + ROOT_ROUTE}/sequencenotes/${id}`,
      search_sequencenotes: `${
        HOST_URL + ROOT_ROUTE
      }/sequencenotes/search?searchTerm=${encodeURI(searchTerm!)}&page=${
        pageNum ?? 0
      }&limit=${RecordPerPage ?? 10}`,
    };
  },

  // system notes
  SystemNoteRoute({ id, pageNum, searchTerm, RecordPerPage }: paramInterface) {
    return {
      get_notes:
        pageNum || RecordPerPage
          ? `${HOST_URL + ROOT_ROUTE}/systemnotes/?page=${pageNum ?? 0}&limit=${
              RecordPerPage ?? 10
            }`
          : `${HOST_URL + ROOT_ROUTE}/systemnotes`,
      add_note: `${HOST_URL + ROOT_ROUTE}/systemnote`,
      update_note: `${HOST_URL + ROOT_ROUTE}/systemnote/${id}`,
      delete_note: `${HOST_URL + ROOT_ROUTE}/systemnote/${id}`,
      get_single_note: `${HOST_URL + ROOT_ROUTE}/systemnote/${id}`,
      get_notes_by_clientId: `${HOST_URL + ROOT_ROUTE}/systemnotes/${id}`,
      notes_reorder: `${HOST_URL + ROOT_ROUTE}/systemnotes/reorder`,
      search_note: `${
        HOST_URL + ROOT_ROUTE
      }/note/search?searchTerm=${encodeURI(searchTerm!)}&page=${
        pageNum ?? 0
      }&limit=${RecordPerPage ?? 10}`,
    };
  },
// SYSTEMREMINDERROUTES
  SystemReminderRoute({ id, pageNum, searchTerm, RecordPerPage }: paramInterface) {
    return {
      get_reminders:
        pageNum || RecordPerPage
          ? `${HOST_URL + ROOT_ROUTE}/systemreminders/?page=${pageNum ?? 0}&limit=${
              RecordPerPage ?? 10
            }`
          : `${HOST_URL + ROOT_ROUTE}/systemreminders`,
      add_reminder: `${HOST_URL + ROOT_ROUTE}/systemreminder`,
      update_reminder: `${HOST_URL + ROOT_ROUTE}/systemreminder/${id}`,
      delete_reminder: `${HOST_URL + ROOT_ROUTE}/systemreminder/${id}`,
      get_single_reminder: `${HOST_URL + ROOT_ROUTE}/systemreminder/${id}`,
      get_reminders_by_clientId: `${HOST_URL + ROOT_ROUTE}/systemreminders/${id}`,
      reminders_reorder : `${HOST_URL + ROOT_ROUTE}/systemreminders/reorder`,
      search_reminder: `${
        HOST_URL + ROOT_ROUTE
      }/systemreminder/search?searchTerm=${encodeURI(searchTerm!)}&page=${
        pageNum ?? 0
      }&limit=${RecordPerPage ?? 10}`,
    };
  },

// SystemSequenceRoute
SystemSequenceRoute({ id, pageNum, searchTerm, RecordPerPage }: paramInterface) {
    return {
      get_sequences:
        pageNum || RecordPerPage
          ? `${HOST_URL + ROOT_ROUTE}/systemsequences/?page=${pageNum ?? 0}&limit=${
              RecordPerPage ?? 10
            }`
          : `${HOST_URL + ROOT_ROUTE}/systemsequences`,
      add_sequence: `${HOST_URL + ROOT_ROUTE}/systemsequence`,
      update_sequence: `${HOST_URL + ROOT_ROUTE}/systemsequence/${id}`,
      delete_sequence: `${HOST_URL + ROOT_ROUTE}/systemsequence/${id}`,
      get_single_sequence: `${HOST_URL + ROOT_ROUTE}/systemsequence/${id}`,
      get_sequences_by_clientId: `${HOST_URL + ROOT_ROUTE}/systemsequences/${id}`,
      sequences_reorder: `${HOST_URL + ROOT_ROUTE}/systemsequences/reorder`,

      search_sequence: `${
        HOST_URL + ROOT_ROUTE
      }/systemsequence/search?searchTerm=${encodeURI(searchTerm!)}&page=${
        pageNum ?? 0
      }&limit=${RecordPerPage ?? 10}`,
    };
  },
  // SystemSequenceNoteRoute
  SystemSequenceNoteRoute({ id, pageNum, searchTerm, RecordPerPage }: paramInterface) {
    return {
      get_sequencenotes:
        pageNum || RecordPerPage
          ? `${HOST_URL + ROOT_ROUTE}/systemsequencenotes/?page=${pageNum ?? 0}&limit=${
              RecordPerPage ?? 10
            }`
          : `${HOST_URL + ROOT_ROUTE}/systemsequencenotes`,
      add_sequencenotes: `${HOST_URL + ROOT_ROUTE}/systemsequencenote`,
      update_sequencenotes: `${HOST_URL + ROOT_ROUTE}/systemsequencenote/${id}`,
      delete_sequencenotes: `${HOST_URL + ROOT_ROUTE}/systemsequencenote/${id}`,
      get_single_sequencenotes: `${HOST_URL + ROOT_ROUTE}/systemsequencenote/${id}`,
      get_sequencenotes_by_clientId: `${HOST_URL + ROOT_ROUTE}/systemsequencenotes/${id}`,
      search_sequencenotes: `${
        HOST_URL + ROOT_ROUTE
      }/systemsequencenotes/search?searchTerm=${encodeURI(searchTerm!)}&page=${
        pageNum ?? 0
      }&limit=${RecordPerPage ?? 10}`,
    };
  },

  ReportRoute({ id, pageNum, searchTerm, RecordPerPage }: paramInterface) {
    return {
      getReminderClientReport: `${HOST_URL + ROOT_ROUTE}/report/reminders/client/${id}`,
      getNoteClientReport: `${HOST_URL + ROOT_ROUTE}/report/notes/client/${id}`,
      getCravingClientReport: `${HOST_URL + ROOT_ROUTE}/report/cravings/client/${id}`,
     
    };
  },

  RitualAnswerRoute({ id, pageNum, RecordPerPage, userId, notesId }: RitualAnswersParamI) {
    return {
      get_ritual_answers:`${HOST_URL + ROOT_ROUTE}/ritualAnswers/?page=${pageNum ?? 0}&limit=${RecordPerPage ?? 10 }${(userId ? '&userId='+userId : '')}${(notesId? '&notesId='+notesId : '')}`,
      add_ritual_answer: `${HOST_URL + ROOT_ROUTE}/ritualAnswers`,
      update_ritual_answer: `${HOST_URL + ROOT_ROUTE}/ritualAnswers/${id}`,
      delete_ritual_answer: `${HOST_URL + ROOT_ROUTE}/ritualAnswers/${id}`,
      get_ritual_answer: `${HOST_URL + ROOT_ROUTE}/ritualAnswers/${id}`,
    };
  },
  
  MeasurementValuesRoute({ id, measurementId }: {id?: number, measurementId?: number}) {
    return {
      getAllMeasurementValues: `${HOST_URL + ROOT_ROUTE}/measurementvalues`,
      getMeasurementValuesByMeasurementId: `${HOST_URL + ROOT_ROUTE}/measurementvalues/measurementId/${measurementId}`,
      postMeasurementValue: `${HOST_URL + ROOT_ROUTE}/measurementvalue`,
      updateMeasurementValue: `${HOST_URL + ROOT_ROUTE}/measurementvalue/${id}`,
      deleteMeasurementValue: `/measurementvalue/${id}`,
      getMeasurementValueById: `${HOST_URL + ROOT_ROUTE}/measurementvalue/${id}`,
    };
  },

  PaigeAiRoute({ id, pageNum, searchTerm, RecordPerPage }: paramInterface) {
    return {
      add_refuter: `${HOST_URL + ROOT_ROUTE}/refuter/${id}`,
      add_refuter_feedback: `${HOST_URL + ROOT_ROUTE}/refuter/feedback/${id}`,

      add_or_edit_client_info: `${HOST_URL + ROOT_ROUTE}/clientinfo/${id}`,
      get_client_info: `${HOST_URL + ROOT_ROUTE}/clientinfo/${id}`,

    };
  },

};

