import { useState, useMemo } from "react";
import { Button, DialogBox } from "@lib/Forms";
import { useTitle } from "@lib/Hooks";
import { privateAgent } from "@lib/Requests/AuthRequests";
import { DataTable } from "../../lib/Forms/DataTable";
import { MRT_ColumnDef } from "material-react-table";
import {
  editUserIntitialValues,
  UserIntialFields,
} from "@components/formFields/Users/user-helpers";
import { useQuery, useQueryClient } from "react-query";
import { routesName } from "@lib/RoutesName/Routes";
import DropdownButton from "@components/shared/Dropdown";
import { useSnackbar } from "notistack";
import {
  SearchParams,
  defaultSearchParams,
} from "@pages/Coach/ClientSearchFields";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { Mode } from "@components/shared/dropdown/mode";
import { AddUserProps, UserProps } from "@lib/Types";
import ClientSearchFields from "@pages/Coach/ClientSearchFields";
import {
  TopContainerNoMargin,
  UsersContainer,
  TableContainer,
} from "src/styles/Containers";
import { TitleLarge } from "src/styles/Typography";
import DropDownClientReport, { SelectMenu as ClientDropdownSelectMenu } from "./DropdownClientReport";
import RitualAnswers from "@pages/RitualAnswers/RitualAnswers";

function ClientPage() {
  useTitle("Clients | Paige");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [searchQueries, setSearchQueries] =
    useState<SearchParams>(defaultSearchParams);
    const [filteredData, setFilteredData] = useState([]);
    const navigate = useNavigate();
    
  const [currentRowUserData, setCurrentRowUserData] = useState<{id: number, name: string}|null>(null);
  const [openRitualAnswerDialog, setRitualAnswerDialogState] = useState(false)

  let { isLoading, data } = useQuery(["ClientUsersQuery"], async () => {
    const coachId = Cookies.get("userId");
    const { data } = await privateAgent.get(
      routesName.UserRoute({ id: coachId }).get_clients_of_coach
    );
    if (data.status === 200) {
      const results = data.result.items;
      setFilteredData(results);
      return results;
    }
  });

  const handleSearchQuery = (searchQuery: SearchParams) => {
    setSearchQueries(searchQuery);
    const filteredData = data?.filter((item: any) => {
      // const hasStruggling = item?.coach?.coachId == searchQuery.coachId;
      // const hasUserType = item?.userTypeId == searchQuery.userTypeId;
      const lastClass = item?.classes[0]?.isLast ? "Yes" : "No";
      const HasLastClass =
        lastClass?.toLowerCase().indexOf(searchQuery.classId.toLowerCase()) >
        -1;

      const hasUser =
        item?.userdetails?.firstName
          ?.toLowerCase()
          .indexOf(searchQuery.searchQuery.toLowerCase()) > -1 ||
        item?.email
          ?.toLowerCase()
          .indexOf(searchQuery.searchQuery.toLowerCase()) > -1 ||
        item?.userdetails?.lastName
          ?.toLowerCase()
          .indexOf(searchQuery.searchQuery.toLowerCase()) > -1;

      let toFiltered = true;
      if (searchQuery.searchQuery != "") {
        toFiltered = toFiltered && hasUser;
      }
      if (searchQuery.classId != "0") {
        toFiltered = toFiltered && HasLastClass;
      }

      return toFiltered;
    });

    setFilteredData(filteredData);
  };

  const handleRowClick = (column: any, row: any) => {
    if (column.id === "client") {
      navigate(`/coach/clients/${row.original.id}/notes`);
    }
  };

  //grid defn

  type Class = {
    id: number;
    name: string;
    isLast: boolean;
  };
  type User = {
    id: number;
    email: string;
    userTypeId: number;
    hasAdvancedSettings: boolean;
    isActive: boolean;
    classes: Class[];
    userdetails: {
      id: number;
      userId: number;
      firstName: string;
      lastName: string;
    };
    role: {
      id: number;
      name: string;
    };
    coach: any;
  };
  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorFn: (row) =>
          `${row?.userdetails?.firstName ?? ""} ${
            row?.userdetails?.lastName ?? ""
          }`,
        id: "client",
        header: "Client",
        minSize: 150,
      },
      {
        accessorFn: (row) => `${row?.email}`,
        id: "email",
        header: "Email",
        minSize: 300,
      },
      {
        accessorFn: (row) => `${row?.classes[0]?.isLast ? "True" : "False"}`,
        id: "class",
        header: "Last Class",
        minSize: 300,
      },
      {
        accessorFn: (row) => `No`,
        id: "struggling",
        header: "Struggling",
      },
      {
        id: "actions",
        header: "Actions",
        headerClassName: "header-row",
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
          className: "action",
        },
        size: 50,
        enableResizing: false, //disable resizing for this column
        Cell: ({ cell }) => (
          <>
            <DropDownClientReport
              id={0}
              onItemClick={function (id: number, mode: string): void {
                const user = cell.row.original as User
                if (mode == "note-report") {
                  downloadNoteReport(user.id,`${user.userdetails.firstName}_${user.userdetails.lastName}`)
                }
                if (mode === "reminder-report") {
                  downloadReminderReport(user.id,`${user.userdetails.firstName}_${user.userdetails.lastName}`)
                }
                if(mode == "craving-report"){
                  downloadCravingReport(user.id,`${user.userdetails.firstName}_${user.userdetails.lastName}`)
                }
              }}
            >
              <ClientDropdownSelectMenu onClick={() => {
                setCurrentRowUserData({
                  id: cell.row.original.id,
                  name: cell.row.original.userdetails.firstName + ' ' + cell.row.original.userdetails.lastName 
                })
                setRitualAnswerDialogState(true)
              }}>
                <div>Ritual Answers</div>
              </ClientDropdownSelectMenu>
            </DropDownClientReport>
          </>
        ),
      },
    ],
    []
  );
  //end of grid defn

  const downloadReport = async () => {

    const { data } = await privateAgent.get(
      routesName.UserRoute({}).getCsvUser,
      {
        responseType: "blob", // important
      }
    );
    // const users = response.data.result
    console.log("===>", data)
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "report.csv");
    document.body.appendChild(link);
    link.click();

  }

  const downloadNoteReport = async (clientId:number, fileName: string) => {

    const { data } = await privateAgent.get(
      routesName.ReportRoute({id: clientId}).getNoteClientReport,
      {
        responseType: "blob", // important
      }
    );
    // const users = response.data.result
    console.log("===>", data)
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}_note_report.csv`);
    document.body.appendChild(link);
    link.click();

  }

  const downloadReminderReport = async (clientId:number, fileName: string) => {

    const { data } = await privateAgent.get(
      routesName.ReportRoute({id: clientId}).getReminderClientReport,
      {
        responseType: "blob", // important
      }
    );
    // const users = response.data.result
    console.log("===>", data)
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}_reminder_report.csv`);
    document.body.appendChild(link);
    link.click();

  }

  const downloadCravingReport = async (clientId:number, fileName: string) => {

    const { data } = await privateAgent.get(
      routesName.ReportRoute({id: clientId}).getCravingClientReport,
      {
        responseType: "blob", // important
      }
    );
    // const users = response.data.result
    console.log("===>", data)
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}_cravings_report.csv`);
    document.body.appendChild(link);
    link.click();

  }

  return (
    <>
    {
      openRitualAnswerDialog && currentRowUserData ?
        <DialogBox
          isOpen={openRitualAnswerDialog}
          onClose={() => {
            setRitualAnswerDialogState(false)
            setCurrentRowUserData(null)
          }}
          title={'Ritual Answers ('+currentRowUserData.name+')'}
          className="ritual-answers-dialog"
          content={
            <RitualAnswers userId={currentRowUserData.id} userName={currentRowUserData.name}/>
          }
        />
        : null
    }

      <TopContainerNoMargin>
        <TitleLarge>Clients</TitleLarge>
        <Button
          label={"Download Client Status Report"}
          onClick={() => {
            downloadReport()
          }}
        ></Button>
      </TopContainerNoMargin>
      <UsersContainer>
        <ClientSearchFields
          onSearchQuery={(searchQuery: SearchParams) =>
            handleSearchQuery(searchQuery)
          }
        />
        <TableContainer>
          {filteredData && filteredData.length > 0 && (
            <DataTable
              columns={columns}
              data={filteredData}
              onCellClick={handleRowClick}
            />
          )}
        </TableContainer>
      </UsersContainer>
    </>
  );
}

export default ClientPage;
